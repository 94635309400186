import "./App.css";

function App() {
  return (
    <div class='flex flex-col items-center justify-center min-h-screen py-12 bg-[#212121] md:py-24 lg:py-32 xl:py-40'>
      <div class='container flex flex-col items-center justify-center gap-4 text-center px-4 md:px-6'>
        <div class='space-y-2 text-center'>
          <h1 class='text-4xl font-bold tracking-tighter sm:text-6xl text-white'>
            Welcome to a Faster and Better Experience
          </h1>
          <p class='max-w-[600px] text-gray-400 md:text-xl/relaxed xl:text-xl/relaxed mx-auto pt-5'>
            We've migrated our product to better serve your needs. Thank you for
            being a part of our journey. Click below to explore the new and
            improved experience.
          </p>
        </div>
        <div class='w-full max-w-sm'>
          <a
            class='inline-flex w-full items-center justify-center rounded-md bg-[#121212] px-8 py-3 mt-12 text-sm font-bold text-white shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50'
            href='https://web.quadrupleimpact.com/'
          >
            Explore the New
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
